import React from "react";

const Faqs = () => {
  return (
    <section id="faq" className="faq section">
      {/* Program Structure Section */}
      <div className="container section-title" style={{ marginTop: "40px" }}>
        <h2 style={{ textAlign: "center", fontWeight: "bold", fontSize: "2.5rem" }}>PROGRAM STRUCTURE</h2>
      </div>
      <div className="container" style={{ maxWidth: 1200, margin: "20px auto", padding: "20px" }}>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div
              style={{
                background: "#f4f4f4",
                borderRadius: "12px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                padding: "30px",
              }}
            >
              {[
                { title: "Leadership and Innovation Management Training", icon: "fas fa-tasks" },
                { title: "Benchmarking and Site Visits", icon: "fas fa-chart-line" },
                { title: "Community of Practice Virtual Engagement Training", icon: "fas fa-users" },
                { title: "Graduation and Certification", icon: "fas fa-lightbulb" },
              ].map((item, index) => (
                <div
                  key={index}
                  style={{
                    background: "#fff",
                    borderRadius: "8px",
                    marginBottom: "20px",
                    padding: "20px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease",
                  }}
                >
                  <h3 style={{ display: "flex", alignItems: "center", fontSize: "1.5rem", color: "#333" }}>
                    <i
                      className={item.icon}
                      style={{ marginRight: "15px", color: "#1d3557" }}
                    />
                    {item.title}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Project Gallery Section */}
      <div
        className="container mt-5"
        style={{
          backgroundColor: "#1d3557",
          borderRadius: "12px",
          padding: "50px 20px",
          color: "white",
        }}
      >
        <h3
          style={{
            textAlign: "center",
            marginBottom: "30px",
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        >
          PROJECT BY COUNTRY
        </h3>
        <div
          className="gallery-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "20px",
            padding: "0 10px",
          }}
        >
          {[
            { country: "Uganda – Kampala", image: "assets/img/moja7.jpg" },
            { country: "Rwanda – Kigali", image: "assets/img/MOJA6.jpg" },
            { country: "Kenya – Nairobi", image: "assets/img/MOJA5.jpg" },
            { country: "South Sudan – Juba", image: "assets/img/DSKILLS.jpg" },
            { country: "Tanzania – Dar-es-salaam", image: "assets/img/MOJA2.jpg" },
            { country: "DRC – Lubumbashi", image: "assets/img/MOJA4.jpg" },
            { country: "Burundi – Bujumbura", image: "assets/img/MOJA8.jpg" },
          ].map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                overflow: "hidden",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                textAlign: "center",
              }}
            >
              <img
                src={item.image}
                alt={`${item.country} Project`}
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                }}
              />
              <h4
                style={{
                  padding: "15px 10px",
                  color: "#1d3557",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                {item.country}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;




