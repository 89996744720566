import React from "react";

const TimeLine = () => {
  return (
    <section
      id="timeline"
      className="timeline section"
      style={{
        backgroundColor: "#f9f9f9",
        padding: "60px 0",
        color: "#333",
      }}
    >
      <div className="container">
        <h3
          style={{
            textAlign: "center",
            marginBottom: "40px",
            fontSize: "2rem",
            fontWeight: "600",
            color: "#444",
          }}
        >
         ACHIEVEMENT
        </h3>
        <div className="row justify-content-center">
          {/* Timeline Card */}
          <div className="col-md-8 mb-4">
            <div
              className="card"
              style={{
                border: "none",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                backgroundColor: "#fff",
                transition: "transform 0.2s ease-in-out",
              }}
            >
              <ul
                style={{
                  listStyleType: "none",
                  paddingLeft: 0,
                  color: "#333",
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: "15px",
                    paddingLeft: "25px",
                    position: "relative",
                    fontSize: "1rem",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: 0,
                      top: "5px",
                      color: "#0056b3",
                      fontSize: "1.5rem",
                    }}
                  >
                    •
                  </span>
                  Participants gained a deeper understanding of key terminologies in innovation such as the difference between customers and beneficiaries, crucial for operational efficiency.
                </li>
                <li
                  style={{
                    marginBottom: "15px",
                    paddingLeft: "25px",
                    position: "relative",
                    fontSize: "1rem",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: 0,
                      top: "5px",
                      color: "#0056b3",
                      fontSize: "1.5rem",
                    }}
                  >
                    •
                  </span>
                  The trainers provided insights into sources of funding and how to develop a robust fundraising strategy.
                </li>
                <li
                  style={{
                    marginBottom: "15px",
                    paddingLeft: "25px",
                    position: "relative",
                    fontSize: "1rem",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: 0,
                      top: "5px",
                      color: "#0056b3",
                      fontSize: "1.5rem",
                    }}
                  >
                    •
                  </span>
                  A strong commitment to ongoing learning and collaboration. It also provides a centralized way to offer continued support, such as through the Consortium of Uganda Universities Innovation Network.
                </li>
                <li
                  style={{
                    marginBottom: "15px",
                    paddingLeft: "25px",
                    position: "relative",
                    fontSize: "1rem",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: 0,
                      top: "5px",
                      color: "#0056b3",
                      fontSize: "1.5rem",
                    }}
                  >
                    •
                  </span>
                  Participants showed interest in partnering with host institutions to give students access to incubators and field attachments for practical experience.
                </li>
              </ul>
            </div>
          </div>
          {/* End Timeline Card */}
        </div>
      </div>
    </section>
  );
};

export default TimeLine;


