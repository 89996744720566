import React from "react";

const CallForApplication = () => {
  return (
    <section
      id="call-for-application"
      className="call-for-application section"
      style={{
        padding: "40px 20px 80px",
        background: "linear-gradient(135deg, #1d3557, #1d3557)",
        color: "white",
        borderRadius: "8px",
        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: "1200px",
        margin: "0 auto 60px",
      }}
    >
      <div style={{ 
        display: "grid", 
        gridTemplateColumns: "1fr 1fr",
        gap: "40px",
        alignItems: "start",
        maxWidth: "1100px",
        margin: "0 auto"
      }}>
        {/* Closing Ceremony Section */}
        <div
          className="ceremony-container"
          style={{
            padding: "20px",
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "12px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2
            style={{
              fontSize: "1.8rem",
              fontWeight: "bold",
              color: "#f1faee",
              marginBottom: "25px",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            Closing Ceremony
          </h2>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <img
              src="/assets/img/artwork.png"
              alt="Closing Ceremony"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
            <p
              style={{
                fontSize: "0.95rem",
                lineHeight: "1.6",
                color: "#e9ecef",
                textAlign: "left",
                margin: "0",
              }}
            >
              The program concluded with a memorable closing ceremony that brought together
              participants, stakeholders, and program leaders. This event marked the
              successful completion of our initiatives and celebrated the achievements
              of all involved parties.
            </p>
          </div>
        </div>

        {/* Impact Section */}
        <div
          className="impact-container"
          style={{
            padding: "20px",
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "12px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2
            style={{
              fontSize: "1.8rem",
              fontWeight: "bold",
              color: "#f1faee",
              marginBottom: "25px",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            Impact
          </h2>
          <p
            style={{
              fontSize: "0.95rem",
              lineHeight: "1.6",
              color: "#e9ecef",
              textAlign: "justify",
            }}
          >
            The in-person trainings and virtual engagement on Incubation Leadership
            and Innovation Management and Benchmarking are widely successful.<br />
            <br />
            <strong>129 university hub managers and technology transfer officers</strong>{" "}
            are equipped with essential skills in innovation management, IP
            management, commercialization, and sustainability strategies.<br />
            <br />
            <strong>1 Consortium of University Hubs</strong> was founded in Uganda, 
            demonstrating the program's important role as a catalyst for networking 
            among the Hubs.<br />
            <br />
            The training provided valuable insights, networking opportunities, and actionable
            outcomes. Through this initiative, the program not only enhances the skills 
            of university staff but also helps to position East African universities 
            as pivotal players in the region's digital and innovation-driven economy.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CallForApplication;









